import React, { useEffect, useRef, useState } from "react";
import {
  CUSTOMER_INFO,
  GA_EVENT_NAME,
  getEventStatus,
  i18nUtil,
  isClient,
  reportEvent
} from "@aspen/libs";
import { useRouter, withRouter } from "next/router";
import {
  EventMore,
  EventBanner,
  EventRegisterInfo,
  EventTag,
  ModalRegister,
  ModalRegisterCancel,
  ModalRegisterSuccess
} from "@aspen/widgets";
import {
  bookEvents,
  cancelBookEvents,
  getConferenceBookStatus,
  getEventsDetail
} from "@aspen/services";
import type { ConferenceVO, IEventStatus, IInjectProps, TRegisterEventsReq } from "@aspen/model";
import styles from "@aspen/theme/Event.module.less";
import { CommonVideo, HtmlContentComponent, message } from "@aspen/ui";

interface IProps extends IInjectProps {}

const EventDetailView: React.FC<IProps> = (props) => {
  const intl = i18nUtil.t();
  const router = useRouter();
  const customer_info = isClient && window.localStorage.getItem(CUSTOMER_INFO);
  const email = customer_info ? JSON.parse(customer_info)?.email : "";
  const id = (router.query?.id as string) || "";
  const [detailInfo, setDetailInfo] = useState<ConferenceVO | undefined>(undefined);
  const rightViewRef = useRef<any>(null);
  const [leftHeight, setLeftHeight] = useState<number>(823.5); // 设计稿最小高度
  const [status, setStatus] = useState<IEventStatus>();
  const [modalRegisterCancel, setModalRegisterCancel] = useState<boolean>(false);
  const [modalRegisterSuccess, setModalRegisterSuccess] = useState<boolean>(false);
  const [modalRegister, setModalRegister] = useState<boolean>(false);
  const [bookedAttendance, setBookedAttendance] = useState<number>(1);
  const [bookStatus, setBookStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    getEventsDetail({ id }).then((res) => {
      if (res?.code == "0") {
        setDetailInfo(res?.data);
      }
    });
  }, [id]);
  useEffect(() => {
    id && _getConferenceBookStatus();
  }, [id]);
  useEffect(() => {
    setStatus(getEventStatus(detailInfo?.startTime, detailInfo?.endTime));
  }, [detailInfo]);
  const _getConferenceBookStatus = () => {
    getConferenceBookStatus({ conferenceId: id })
      .then((res) => {
        if (res?.code == "0") {
          setBookStatus(!!res.data);
        }
      })
      .catch(() => {});
  };
  const bookRegister = (num: number, name?: string) => {
    setLoading(true);
    let params: TRegisterEventsReq = {
      conferenceId: id || "",
      attendNum: num
    };
    if (name) params.otherName = name;
    bookEvents(params)
      .then((res) => {
        if (res?.code == "0") {
          setBookedAttendance(num);
          setModalRegister(false);
          setModalRegisterSuccess(true);
          setBookStatus(true);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch((err) => {
        message.error(err?.msg);
      })
      .finally(() => {
        reportEvent({
          moduleName: GA_EVENT_NAME.event.bookEvent,
          detailParams: {
            conferenceId: id || "",
            attendNum: num
          }
        });
        setLoading(false);
      });
  };
  const cancelBook = () => {
    setLoading(true);
    cancelBookEvents({ conferenceId: id })
      .then((res) => {
        if (res?.code == "0") {
          message.success(intl["event.cancel.toast"]);
          bookStatus && setBookStatus(false);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch((err) => {
        message.error(err?.msg);
      })
      .finally(() => {
        reportEvent({
          moduleName: GA_EVENT_NAME.event.cancelBookEvents,
          detailParams: {
            conferenceId: id || ""
          }
        });
        setModalRegisterCancel(false);
        setLoading(false);
      });
  };
  const eventRegister = () => {
    if (detailInfo?.type === "ONLINE") {
      detailInfo?.address && window.open(detailInfo?.address);
      reportEvent({
        moduleName: GA_EVENT_NAME.event.bookEventOuterLink,
        detailParams: {
          address: detailInfo?.address || ""
        }
      });
    } else {
      setModalRegister(true);
    }
  };

  const _renderTop = () => {
    return (
      <div className={styles.top}>
        <EventTag status={status} style={{ position: "absolute", left: 0, top: 0 }} />
        <div className={styles.topTitle}>{detailInfo?.title}</div>
        <div className="flex" style={{ alignItems: "flex-start" }}>
          <EventBanner
            data={detailInfo}
            status={status}
            width={710}
            height={310}
            countStatusEnd={() => {
              setStatus(getEventStatus(detailInfo?.startTime, detailInfo?.endTime));
            }}
          />
          <EventRegisterInfo
            loading={loading}
            data={detailInfo}
            status={status}
            bookStatus={bookStatus}
            register={eventRegister}
            cancel={() => {
              setModalRegisterCancel(true);
            }}
          />
        </div>
      </div>
    );
  };
  const _renderBottom = () => {
    return (
      <div className={styles.bottom}>
        <div className={styles.left} style={{ height: leftHeight }}>
          <span className={styles.bottomTitle}>{intl["event.detail.about"]}</span>
          {detailInfo?.video ? (
            <div style={{ marginTop: 20 }}>
              <CommonVideo url={detailInfo?.video} />
            </div>
          ) : null}
          {detailInfo?.detail ? (
            <HtmlContentComponent
              withSpin={false}
              htmlContent={detailInfo?.detail}
              style={{
                minHeight: 300,
                justifyContent: "flex-start",
                marginTop: 20
              }}
            />
          ) : null}
        </div>
        <div className={styles.right} ref={rightViewRef}>
          <span className={styles.bottomTitle} style={{ marginBottom: 24 }}>
            {intl["event.detail.moreEvents"]}
          </span>
          <EventMore
            key={id}
            curId={id}
            setHeight={(height) => {
              height && rightViewRef?.current && setLeftHeight(rightViewRef.current.clientHeight);
            }}
          />
        </div>
      </div>
    );
  };
  return (
    <div className={styles.detailInfo}>
      {_renderTop()}
      {_renderBottom()}
      {modalRegister ? (
        <ModalRegister
          loading={loading}
          visible={modalRegister}
          handleCancel={() => {
            setModalRegister(false);
          }}
          handleOk={(num, name) => {
            bookRegister(num, name);
          }}
        />
      ) : null}
      {modalRegisterCancel ? (
        <ModalRegisterCancel
          visible={modalRegisterCancel}
          handleCancel={() => {
            setModalRegisterCancel(false);
          }}
          handleOk={() => {
            cancelBook();
          }}
        />
      ) : null}
      {modalRegisterSuccess ? (
        <ModalRegisterSuccess
          attendance={bookedAttendance}
          text={
            <p>
              {intl["event.register.confirm.success.content"]}
              <span className={styles.speColor}>({email})</span>
            </p>
          }
          visible={modalRegisterSuccess}
          handleCancel={() => {
            setModalRegisterSuccess(false);
          }}
          handleOk={() => {
            setModalRegisterSuccess(false);
          }}
        />
      ) : null}
    </div>
  );
};
export const PageEventDetail = withRouter(React.memo(EventDetailView));
