import type { ReactNode } from "react";
import React from "react";
import { withRouter } from "next/router";
import { Col, Row, Spin } from "antd";
import { isEqual } from "lodash-es";
import styles from "@aspen/theme/Home.module.less";
import type { IAssetWalletInfo, IFlexibleProducts, IInjectProps, IKycLevel } from "@aspen/model";
import { ADGM_BRAND, BRAND, COMMON_CACHE_PRIORTY_KEYS } from "@aspen/model";
import {
  certifiedKycLevel,
  CUSTOMER_INFO,
  DEVICE_ID,
  GA_ENTRANGE_TYPE,
  IS_AUTHENTICATED,
  KYC_CERTIFIED_STATUS,
  reportGaInfo,
  SKIP_CHANGE_PASSWORD,
  systemDetection,
  TrackerReport,
  VIP_PATHS
} from "@aspen/libs";
import {
  Announcements,
  AssetDistribution,
  AssetSituationalNoDeposit,
  MarketsCard,
  ModalChangePassword,
  ModalNeedChangePwd,
  NotKyc2Mask,
  PopularSavings,
  TotalAssetsLine,
  VipInfoCard,
  WellStrategy,
  WellVenture
} from "@aspen/widgets";
import { CommonChaceData, WithKYCInfo } from "@aspen/ui";
import QuickEntranceView from "@aspen/widgets/home/QuickEntranceView";
import { connect } from "react-redux";

interface IState {
  showNeedChangePwdModal: boolean;
  showChangePwdModal: boolean;
  yieldProducts: IFlexibleProducts[];
}

interface IProps extends IInjectProps {
  showGuideModal: (level: IKycLevel, isClose?: boolean) => boolean;
  walletInfo: IAssetWalletInfo;
  updateWalletInfo: (walletInfo: IAssetWalletInfo) => void;
  showWellVenture?: boolean;
  showWellStrategy?: boolean;
  showPopularSavings?: boolean;
}

class Home extends React.Component<IProps, IState> {
  system = systemDetection();
  constructor(props) {
    super(props);
    this.state = {
      showNeedChangePwdModal: false,
      showChangePwdModal: false,
      yieldProducts: []
    };
  }

  componentDidMount: () => void = async () => {
    // 登录上报设备信息
    !localStorage.getItem(DEVICE_ID) && reportGaInfo(2);

    // 首页判断是否为帮客注册需要更改密码
    const skipChangePassword = localStorage.getItem(SKIP_CHANGE_PASSWORD);
    const customer_info = localStorage.getItem(CUSTOMER_INFO);
    // @ts-ignore
    const defaultPwd = JSON.parse(customer_info)?.defaultPwd;
    if (defaultPwd && !skipChangePassword) {
      this.setState({ showNeedChangePwdModal: true });
    } else {
      // kyc1通过后不提示 ，当用户已登录并且kyc2状态不为审核中和已通过时 弹窗提示去kyc2
      typeof window != "undefined" &&
        localStorage.getItem(IS_AUTHENTICATED) &&
        localStorage.getItem(KYC_CERTIFIED_STATUS) != "4" &&
        this.props.showGuideModal(1);
    }
    // @ts-ignore
    TrackerReport.getInstance("home")._trackerPageReport();
  };

  shouldComponentUpdate(nextProps, nextState): boolean {
    let shouldUpdate = false;
    if (
      !isEqual(nextState.yieldProducts, this.state.yieldProducts) ||
      this.state.showNeedChangePwdModal != nextState.showNeedChangePwdModal ||
      this.state.showChangePwdModal != nextState.showChangePwdModal ||
      this.props.walletInfo?.totalAmount != nextProps.walletInfo?.totalAmount ||
      this.props.showWellVenture != nextProps.showWellVenture ||
      this.props.showWellStrategy != nextProps.showWellStrategy ||
      this.props.showPopularSavings != nextProps.showPopularSavings
    ) {
      shouldUpdate = true;
    }
    return shouldUpdate;
  }

  componentWillUnmount: () => void = () => {
    setTimeout(() => {
      // @ts-ignore
      TrackerReport.getInstance()._removeTrackerPageReport();
    }, 200);
  };

  // 总资产曲线图模块
  renderPortfolio = () => {
    const { yieldProducts } = this.state;
    const { walletInfo } = this.props;
    if (!walletInfo) {
      return <Spin spinning> </Spin>;
    }
    if (walletInfo?.totalAmount > 0) {
      return <TotalAssetsLine lineHeight={230} totalAmount={walletInfo?.totalAmount} />;
    }
    return (
      <AssetSituationalNoDeposit
        showGuideModal={this.props.showGuideModal}
        yieldProducts={yieldProducts?.slice(0, 2)}
        gaExtra={{ entrance: GA_ENTRANGE_TYPE.homeTop }}
      />
    );
  };

  showVentureView = () => {
    const { showWellStrategy = true, showWellVenture = true } = this.props;
    if (this.system === BRAND && showWellVenture) {
      return <WellVenture />;
    } else if (this.system === ADGM_BRAND && showWellStrategy) {
      return <WellStrategy />;
    }
    return null;
  };
  showStrategyView = () => {
    const { showWellStrategy = true, showWellVenture = true } = this.props;
    if (this.system === BRAND && showWellStrategy) {
      return <WellStrategy />;
    } else if (this.system === ADGM_BRAND && showWellVenture) {
      return <WellVenture />;
    }
    return null;
  };

  render(): ReactNode {
    const {
      walletInfo,
      showWellVenture = true,
      showWellStrategy = true,
      showPopularSavings = true
    } = this.props;
    const { yieldProducts } = this.state;
    return (
      <section className={styles.overview}>
        <div className={styles.topCardWrapper}>
          <VipInfoCard
            onPress={() => {
              if (this.props.showGuideModal(1)) {
                this.props.router.push(VIP_PATHS.VIP);
              }
            }}
          />
          <MarketsCard />
        </div>
        <Row gutter={16}>
          <Col span={8}>
            {/* @ts-ignore */}
            <AssetDistribution showGuideModal={this.props.showGuideModal} {...this.props} />
          </Col>
          <Col span={16}>
            <Announcements />
            <div style={{ position: "relative" }}>
              {!certifiedKycLevel(1) && <NotKyc2Mask />}
              <div
                className={`${styles.assetSituational} middleAlign`}
                style={{
                  // @ts-ignore
                  filter: typeof window == "undefined" || certifiedKycLevel(1) ? null : "blur(2px)"
                }}>
                {this.renderPortfolio()}
              </div>
            </div>
            <QuickEntranceView {...this.props} />
          </Col>
        </Row>
        {this.showVentureView()}
        {this.showStrategyView()}
        {showPopularSavings ? (
          <PopularSavings
            type={walletInfo?.totalAmount > 0 ? "inFunds" : "noDeposit"}
            yieldProducts={yieldProducts}
            showGuideModal={this.props.showGuideModal}
          />
        ) : null}
        {this.state.showNeedChangePwdModal ? (
          <ModalNeedChangePwd
            visible={this.state.showNeedChangePwdModal}
            handleCancel={() => {
              localStorage.setItem(SKIP_CHANGE_PASSWORD, "true");
              this.setState({ showNeedChangePwdModal: false });
              this.props.showGuideModal(2);
            }}
            handleOk={() => {
              localStorage.setItem(SKIP_CHANGE_PASSWORD, "true");
              this.setState({ showNeedChangePwdModal: false, showChangePwdModal: true });
            }}
          />
        ) : null}
        {this.state.showChangePwdModal ? (
          <ModalChangePassword
            visible={this.state.showChangePwdModal}
            handleCancel={() => this.setState({ showChangePwdModal: false })}
          />
        ) : null}
        {/* 加载walletInfo数据 */}
        <CommonChaceData cachekey={COMMON_CACHE_PRIORTY_KEYS.walletInfo} />
        {/* 资产数据与打点数据 */}
        <CommonChaceData cachekey={COMMON_CACHE_PRIORTY_KEYS.assetsLineData} />
        {/* 加载活期理财数据 */}
        <CommonChaceData
          cachekey={COMMON_CACHE_PRIORTY_KEYS.yieldFlexibleProducts}
          callback={(data) => {
            this.setState({ yieldProducts: data?.data });
          }}
        />
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  const { walletInfo, isShowAmount } = state.wallet;

  return {
    walletInfo,
    isShowAmount
  };
};
const mapDispatchToProps = () => {
  return {};
};

export const PageHome = connect(
  mapStateToProps,
  mapDispatchToProps()
)(WithKYCInfo(withRouter(Home)));
